import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchKarmaCategories(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/karma-categories", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchKarmaCategoryStatus() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/karma-categories-status")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchKarmaCategory(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`karma-categories/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addKarmaCategory(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("karma-categories", userData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(new Error(values[0][0]));
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateKarmaCategory(ctx, karma_category) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`karma-categories/${karma_category.id}`, karma_category.data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(new Error(values[0][0]));
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeKarmaCategory(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`karma-categories/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleKarmaCategories(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/karma-categories/destroy`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/karma-categories/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
    }
};
